import React, { useEffect, useState } from "react"
import {
  Container,
  LeftContainer,
  OptionContainer,
  RightContainer,
} from "./style"
import { ProgressBar } from "react-bootstrap"
import Button from "../Reusable/Button"
import { navigate } from "gatsby"
import MyKelasList from "../CourseComponent/kelas-list"
import Input from "../Reusable/Input"
import Textarea from "../Reusable/Input/textarea"
import { StyledCourseDescWrap } from "../CourseVideoComponent/style"
import CourseProgress from "../CourseVideoComponent/progress"
import {
  submitQuizAnswer,
  getQuizHistoryByQuizId,
  getCourseProgress,
} from "../../services/course"
import { toast } from "react-toastify"
import moment from "moment"
import Spinner from "../Reusable/Spinner"

const QuizComponent = ({ course, itemId, user }) => {
  useEffect(() => {
    if (course && course.is_owned === false) {
      navigate(`/course?id=${course.id}`)
    }
  }, [course, itemId])

  const [description, setDescription] = useState("")
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState([])
  const [page, setPage] = useState(0)
  const [history, setHistory] = useState([])
  const [passingGrade, setPassingGrade] = useState(-1)
  const [progress, setProgress] = useState(-1)
  const [status, setStatus] = useState("loading") // loading, never, retry, working

  useEffect(() => {
    getCourseProgress(course.id).then(resp =>
      setProgress(resp.data?.percentage)
    )
  }, [])

  useEffect(() => {
    if (parseInt(itemId) > 0) {
      getHistory()
      getQuestions()
    }
  }, [course, itemId])

  const getHistory = () => {
    getQuizHistoryByQuizId(getQuizId()).then(resp => {
      setHistory(resp.data.results)
      if (resp.data.count) {
        setStatus("retry")
      } else {
        setStatus("never")
      }
    })
  }

  const getQuizId = () => {
    for (const chapter of course.chapters) {
      for (const item of chapter?.items) {
        if (item?.id === parseInt(itemId)) return item.quiz.id
      }
    }
  }

  const getQuestions = () => {
    course?.chapters?.forEach(chapter => {
      chapter?.items?.forEach(item => {
        if (item?.id === parseInt(itemId)) {
          setDescription(item?.quiz?.description)
          setQuestions(item?.quiz?.quiz_questions)
          setAnswers(generateAnswers())
          setPassingGrade(item?.quiz?.passing_grade)
        }
      })
    })
  }

  const generateAnswers = () => {
    const answers = []
    for (const question of questions) {
      answers.push([question.id, ""])
    }
    return answers
  }

  const handleAnswer = (id, newAnswer) => {
    const items = [...answers]
    items[page] = [id, newAnswer]
    setAnswers(items)
  }

  const submitAnswer = () => {
    setStatus("loading")
    const body = {
      quiz: getQuizId(),
      answer: JSON.stringify(answers),
    }
    submitQuizAnswer(body)
      .then(() => {
        getHistory()
        getCourseProgress(course.id).then(resp =>
          setProgress(resp.data?.percentage)
        )
      })
      .catch(() => {
        toast.error("An error occured")
      })
    setPage(0)
    setAnswers(generateAnswers())
  }

  return (
    <Container>
      <LeftContainer>
        <p>{description}</p>

        {status === "loading" && (
          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </div>
        )}

        {status === "never" && (
          <div>
            Passing grade: {passingGrade >= 0 ? passingGrade : ""} <br />
            Kamu dinyatakan lulus apabila nilai quiz kamu melebihi passing
            grade. <br /> Apabila gagal, kamu dapat mengulang quiz. <br />
            <br />
            <Button onClick={() => setStatus("working")}>Mulai Quiz</Button>
          </div>
        )}

        {status === "retry" && (
          <div>
            Passing grade: {passingGrade >= 0 ? passingGrade : ""}
            <br />
            Riwayat percobaan:
            {history.length > 0 && (
              <>
                <table>
                  <thead>
                    <tr>
                      <th className="tanggal">Tanggal percobaan</th>
                      <th className="score">Skor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.slice(0, 5).map((item, id) => {
                      return (
                        <tr key={id}>
                          <td className="tanggal">
                            {moment(item.created_at).format("DD MMM YYYY")}
                          </td>
                          <td
                            className="score"
                            style={{
                              background:
                                item.score < passingGrade ? "red" : "green",
                              color: "white",
                            }}
                          >
                            {item.score}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </>
            )}
            <br />
            {history.length > 0 && history[0]?.score < passingGrade ? (
              <>
                Skor quiz terakhirmu belum memenuhi passing grade.
                <br />
                <Button onClick={() => setStatus("working")}>
                  Ulangi Quiz
                </Button>
              </>
            ) : (
              <>
                Selamat! Skor quiz terakhirmu telah memenuhi passing grade dan
                kamu dianggap lulus quiz ini.
              </>
            )}
          </div>
        )}

        {status === "working" && (
          <>
            <ProgressBar now={((page + 1) / questions.length) * 100} />
            <div className="pertanyaan-counter">
              Soal {page + 1}/{questions.length}
            </div>
            <div className="pertanyaan">{questions[page].question}</div>
            <hr />

            <div className="">
              {questions[page].type === "choice" && (
                <OptionContainer>
                  {questions[page].answer.split(/\r?\n/).map((option, i) => {
                    return (
                      <button
                        className=""
                        key={i}
                        onClick={() => handleAnswer(questions[page].id, option)}
                      >
                        <div
                          className={`bullet ${
                            answers[page][1] === option && "active"
                          }`}
                        />
                        {option}
                      </button>
                    )
                  })}
                </OptionContainer>
              )}
              {questions[page].type === "short" && (
                <Input
                  value={answers[page][1]}
                  onChange={e =>
                    handleAnswer(questions[page].id, e.target.value)
                  }
                />
              )}
              {questions[page].type === "essay" && (
                <Textarea
                  rows={5}
                  value={answers[page][1]}
                  onChange={e =>
                    handleAnswer(questions[page].id, e.target.value)
                  }
                />
              )}
            </div>
            <hr />
            <div className="button-container">
              <div>
                <Button
                  className="secondary"
                  disabled={page === 0}
                  onClick={() => setPage(page - 1)}
                >
                  Previous
                </Button>
                <Button
                  className="secondary"
                  disabled={page === questions.length - 1}
                  onClick={() => setPage(page + 1)}
                >
                  Next
                </Button>
              </div>
              {page === questions.length - 1 && (
                <div>
                  <Button onClick={() => submitAnswer()}>Selesai</Button>
                </div>
              )}
            </div>
          </>
        )}
      </LeftContainer>
      <RightContainer>
        <h4 className="judul-content-right course-desc-wrap">{course.title}</h4>
        {progress >= 0 && (
          <StyledCourseDescWrap>
            <CourseProgress progress={progress} courseId={course.id} />
          </StyledCourseDescWrap>
        )}
        <div className="class-overview">
          <div className="daftar-isi">
            <div>
              <MyKelasList
                chapters={course.chapters}
                courseId={course.id}
                beli={course.is_owned}
                chosen={parseInt(itemId)}
              />
            </div>
          </div>
        </div>
      </RightContainer>
    </Container>
  )
}

export default QuizComponent
