// Generic imports
import React, { forwardRef } from "react"
import styled from "styled-components"

const Styles = styled.div`
  display: inline-block;
  width: 100%;

  textarea {
    width: 100%;
  }
`

const TextArea = forwardRef((props, ref) => {
  const { label, id, ...otherProps } = props
  const inputId = id !== null ? id : props.name

  return (
    <Styles className="taman-input">
      {label ? <label htmlFor={inputId}>{label}</label> : <></>}
      <textarea ref={ref} id={inputId} {...otherProps} />
    </Styles>
  )
})

TextArea.displayName = "TextArea"

export default TextArea
